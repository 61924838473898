import {environment} from '../../environments/environment';


export class User {
  name: string;
  email: string;
  picture: string;
  emailConfirm: boolean;
  groups: string[];
  resources: string[];
  fullAccess: boolean;

  constructor(data: any = {}) {
    this.name = data.fname && data.email || data.email;
    this.email = data.email && data.email || '';
    this.picture = data.picture && data.picture || '../../assets/img/profile.png';
    this.groups = data.groups && data.groups || null;
    this.emailConfirm = data.emailConfirmed && data.emailConfirmed || false;

    this.resources = data.resources && data.resources
        .filter(value => value.toLowerCase()
          .includes(environment.moduleName.toLowerCase())) || null;

    this.fullAccess = (data.userRoles && data.userRoles.includes('ROLE_SUPER_ADMIN')) && true || false;
  }
}
