import { Action, createReducer, on } from '@ngrx/store';
import * as fromAuth from '../actions/auth.action';
import { User } from '../../models/user.model';


export interface AuthState {
  userData: User;
}

export const initialAuthState: AuthState = {
  userData: null
};

const authReducers = createReducer(
  initialAuthState,
  on(fromAuth.setUserDataAction, (state, data) => ({...state, userData: new User(data)})),
  on(fromAuth.clearUserDataAction, (state) => ({...state, userData: null}))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return authReducers(state, action);
}
