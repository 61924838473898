// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { VideoChatModule } from '@mangosoft/video-chat';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { ClipboardModule } from 'ngx-clipboard';
import { PopoverModule } from 'ngx-bootstrap/popover';

// components
import { VideoChatComponent } from './video-chat.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


const routes: Routes = [
  {path: '', component: VideoChatComponent},
  {path: ':room', component: VideoChatComponent},
  {path: ':room/:username', component: VideoChatComponent},
];

@NgModule({
  declarations: [VideoChatComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PopoverModule.forRoot(),
    VideoChatModule,
    ReactiveFormsModule,
    ComponentsModule,
    ClipboardModule,
    BsDatepickerModule,
  ]
})
export class VideoPageChatModule { }
