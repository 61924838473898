// modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guard
import { AuthGuardService } from './services/auth.guard';

// components
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'public',
    loadChildren: () => import('./public-video-chat/public-video-chat.module').then(m => m.PublicVideoChatModule)
  },
  {
    path: '',
    // canActivate: [ AuthGuardService ],
    component: MainComponent,
    children: [
      {path: 'register-video-chat', component: AuthComponent},
      {
        path: 'video-chat',
        loadChildren: () => import('./video-chat/video-chat.module').then(m => m.VideoPageChatModule)
      },
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule)
      },
      { path: '', redirectTo: '/chat', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule { }
