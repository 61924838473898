import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';

// NgRx
import { Observable } from 'rxjs';
import { State } from '../reducers';
import { Store } from '@ngrx/store';
import * as fromUIActions from '../store/actions/ui.action';
import { finalize } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Constructor
   *
   * @param {AuthService} authService
   * @param {Store} store
   */
  constructor(
    private authService: AuthService,
    private store: Store<State>
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If there is no token we send the request like this, it can be the login
    if (!this.authService.token) {
      this.store.dispatch(fromUIActions.uiLoading());
      return next.handle(request).pipe(
        finalize(() => this.store.dispatch(fromUIActions.uiLoaded()))
      );
    }

    // Request for refresh token
    if (request.url.includes('refresh-token')) {
      return next.handle(request);
    }

    // Updated headers
    request = request.clone({
      headers: request.headers.set('Authorization', this.authService.token)
    });
    if (!request.headers.has('Content-Type') && request.method !== 'GET') {
      request.headers.set('Content-Type', 'application/json');
    }

    // Check that the token is valid and if it is next to expire update to a new one
    this.authService.refreshToken();

    this.store.dispatch(fromUIActions.uiLoading());
    return next.handle(request).pipe(
      finalize(() => this.store.dispatch(fromUIActions.uiLoaded()))
    );
  }
}
