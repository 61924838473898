export const environment = {
  production: false,
  moduleName: 'MANGO_COMMUNICATIONS_V1',
  firebase: {
    apiKey: 'AIzaSyDqbm2PC12s4s0adB8oMbismOho8PvuNIk',
    authDomain: 'mango-communication.firebaseapp.com',
    databaseURL: 'https://mango-communication.firebaseio.com',
    projectId: 'mango-communication',
    storageBucket: 'mango-communication.appspot.com',
    messagingSenderId: '809699328165',
    appId: '1:809699328165:web:a1592e549c6f53004ecd4c'
  },
  commAPI: 'https://api-comm.mango-soft.com/v1/dev',
  registrationURL: 'https://registration.mango-soft.com',
  apiRegistration: 'https://api-registration.mango-soft.com/v3/prod'
};
