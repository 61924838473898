import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NotificationsService, NotificationType } from 'angular2-notifications';

// NgRx
import { State } from './reducers';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromStore from './store';

// Get Version from package.json
declare var require: any;
const version = require('./../../package.json').version;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'videoChat';

  // notifications
  notOptions = {
    position: ['top', 'right'],
    timeOut: 3000,
    clickToClose: false,
    showProgressBar: true
  };

  /**
   * Constructor
   *
   * @param {Store} store
   * @param {AuthService} authService
   * @param {NotificationsService} notificationsService
   * @param {Router} router
   * @param {Title} titleService
   */
  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private router: Router,
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    // updated title by settings
    this.titleService.setTitle(this.title);

    // check if exist user login and load his data
    this.authService.getCurrentUser();

    // set data in store
    this.store.dispatch(fromStore.setVersion({version}));

    // notifications
    this.checkForNotifications();
  }

  /**
   * Check for notifications
   */
  checkForNotifications(): void {
    this.store.pipe(
      select(fromStore.selectUINotification),
      filter(value => (value.type !== '' && !!value.type)),
    ).subscribe(data => {
      const isSuccess = (data.type !== 'error');
      this.notificationsService
        .create(
          (isSuccess) ? 'Success' : 'Error',
          data.message,
          (isSuccess) ? NotificationType.Success : NotificationType.Error,
          this.notOptions
        );

      // clear msj
      this.store.dispatch(fromStore.clearMessage());
    });
  }
}
