<div class="d-flex align-items-end flex-column" *ngIf="isConnected">
  <button class="p-2 btn btn-light mr-3 mt-3"
  (click)="isConnected = false"><i class="fas fa-plug"></i></button>
</div>

<div class="container mt-3">
  <div class="d-flex flex-row justify-content-center">

    <div class="p-4 chat-container">

      <div *ngIf="isConnected; then chatTemplate else enterNameTemplate"></div>

      <ng-template #chatTemplate>
        <mangosoft-chat
          [roomName]="roomName"
          [member]="memberData"
        ></mangosoft-chat>
      </ng-template>

      <ng-template #enterNameTemplate>
        <form [formGroup]="memberForm">
          <div class="form-group mt-3">
            <label for="inpRoom">Room</label>
            <input id="inpRoom" type="text" class="form-control" placeholder="Room name"
                   formControlName="room">
          </div>
          <div class="form-group mt-3">
            <label for="inpName">Name</label>
            <input id="inpName" type="text" class="form-control" placeholder="Enter your name"
                   formControlName="name">
          </div>
          <div class="form-group">
            <label for="inpEmail">Email</label>
            <input id="inpEmail" type="text" class="form-control" placeholder="Enter your email"
                   formControlName="email">
          </div>
          <div class="form-group">
            <input type="button" class="btn btn-primary zfcolorbg zfcolorborder" value="Connect"
                   [disabled]="memberForm.invalid"
                   (click)="connect()">
          </div>
        </form>
      </ng-template>

    </div>
  </div>
</div>
