import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VideoChatService {

  activedVideoChat = false;

  /**
   * Constructor
   *
   * @param {HttpClient} http
   */
  constructor(
    private http: HttpClient
  ) {
  }

  set actVideoChat(value: boolean) {
    this.activedVideoChat = value;
  }

  /**
   * load rooms
   *
   * @return Observable<any>
   */
  loadRooms(): Observable<any> {
    return this.http.get(`${environment.commAPI}/rooms`);
  }

  /**
   * Save rooms
   *
   * @param {*} room
   * @return Observable<any>
   */
  saveRooms(room: any): Observable<any> {
    return this.http.post(`${environment.commAPI}/rooms`, room);
  }

  /**
   * Get short link
   *
   * @param {*} payload
   * @return Observable<any>
   */
  getShortLink(payload: any): Observable<any> {
    return this.http.post(`${environment.commAPI}/links`, payload);
  }

  /**
   * Send confirmation code
   *
   * @param {string} token
   * @return Observable<any>
   */
  sendConfirmationCode(token: string): Observable<any> {
    // 204
    // atob
    const header = new HttpHeaders({Authorization: token});
    return this.http.post(`${environment.apiRegistration}/hotp/send`, {}, {headers: header});
  }

  /**
   * Validate
   *
   * @param {string} token
   * @param {number} code
   * @return Observable<any>
   */
  validate(token: string, code: number) {
    const header = new HttpHeaders({Authorization: token});
    return this.http.post(`${environment.apiRegistration}/hotp/verify`, {token: code}, {headers: header});
  }
}
