// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ChatModule } from '@mangosoft/chat';

// components
import { ChatComponent } from './chat.component';


const routes: Routes = [
  {
    path: '',
    component: ChatComponent
  }
];

@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ChatModule,
    ReactiveFormsModule,
  ]
})
export class ChatPageModule { }
