import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IMember } from '@mangosoft/chat/lib/interfaces/chat';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {

  isConnected = false;
  roomName: string;
  memberForm: FormGroup;
  memberData: IMember;

  /**
   * Constructor
   */
  constructor() {
    this.memberForm = new FormGroup({
      room: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required)
    });
  }

  connect() {
    this.roomName = this.memberForm.value.room;
    const data = this.memberForm.value;
    delete data.room;
    this.memberData = {...data, userId: data.email};
    this.isConnected = true;
  }

}
