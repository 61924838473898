import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, replace: string, replaceBy: string): string {
    const regex = new RegExp(replace, 'g');
    return value.replace(regex, replaceBy);
  }

}
