import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VideoChatService } from '../services/video-chat.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  @ViewChild('roomInp') roomInp: ElementRef;

  authForm: FormGroup;
  shortLink: string;
  token: string;
  loading = false;
  linkCopied = false;
  validate = false;
  notification: string;

  constructor(
    private router: Router,
    private actRouter: ActivatedRoute,
    private videoChatService: VideoChatService
  ) {
  }

  ngOnInit(): void {
    this.token = this.actRouter.snapshot.queryParams.token;
    (!!this.token) ? this.handleValidationView() : this.handleFormView();
  }

  handleValidationView() {
    this.validate = true;
    this.authForm = new FormGroup({
      code: new FormControl('', Validators.required)
    });
    this.sendValidationCode();
  }

  sendValidationCode() {
    this.loading = true;
    this.notification = null;
    this.videoChatService.sendConfirmationCode(this.token)
      .subscribe(() => {
        console.log('code sent');
        this.loading = false;
      }, () => {
        console.log('error trying to send the code');
        this.loading = false;
      });
  }

  handleFormView() {
    this.validate = false;
    this.authForm = new FormGroup({
      room: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      phone: new FormControl('', Validators.required),
      date: new FormControl(new Date(), Validators.required),
    });
  }

  submit() {
    this.linkCopied = false;
    this.loading = true;
    const date = this.authForm.value.date;
    const utcDate = new Date(date.toUTCString()).toISOString();
    const payload = {...this.authForm.value, date: utcDate};

    this.videoChatService.getShortLink(payload)
      .subscribe((response: any) => {
        if (response.success) {
          this.shortLink = response.data;
        } else {
          this.shortLink = null;
        }
        this.loading = false;
      });

    this.roomInp.nativeElement.focus();
  }

  validateCode() {
    this.loading = true;
    this.notification = null;
    const code: number = Number(this.authForm.value.code);
    this.videoChatService.validate(this.token, code)
      .subscribe((response: any) => {
        if (response.token) {
          this.setNotification('Success!');
          this.videoChatService.actVideoChat = true;
          const tokenValue = response.token.split('.')[1];
          const data = JSON.parse(atob(tokenValue));
          this.router.navigate([`/video-chat/${data.room}/${data.username}`]);
        } else {
          this.setNotification('The code is incorrect :(');
        }
        this.loading = false;
      }, () => {
        this.loading = false;
        this.setNotification('The code is incorrect :(');
      });
  }

  setNotification(text: string) {
    this.notification = text;
    setTimeout(() => this.notification = null, 3000);
  }

}
