import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(listStr: string[], joinWith: string): unknown {
    return listStr.join(joinWith).toString();
  }

}
