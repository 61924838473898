export const topMenu = [
  // {
  //   "name": "Estimates",
  //   "appName": "estimates",
  //   "icon": "icon-registration",
  //   "link": "http://estimates.zerofractal.com/"
  // }, {
  //   "name": "Invoices",
  //   "appName": "invoices",
  //   "icon": "icon-media_channel",
  //   "link": "https://invoices.zerofractal.com/"
  // }, {
  //   "name": "Timesheets",
  //   "appName": "timesheets",
  //   "icon": "icon-competition",
  //   "link": "https://timesheets.zerofractal.com/"
  // }
];

export const leftMenu = [
  // {
  //   "name": "Dashboard",
  //   "activePage": "dashboard",
  //   "link": "dashboard",
  //   "icon": "fa-tachometer",
  //   "active": "yes"
  // },
  {
    name: 'Chat',
    activePage: 'chat',
    link: 'chat',
    icon: 'fa-comment-alt',
    active: 'yes'
  },
  {
    name: 'Video Chat',
    activePage: 'video-chat',
    link: 'video-chat',
    icon: 'fa-play',
    active: 'yes'
  }
];
