import { Component, OnInit } from '@angular/core';
import { leftMenu } from '../../../environments/settings';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  activedPage: string;
  elements: any[];

  /**
   * Contructor
   */
  constructor() {
    this.activedPage = '/';
  }

  ngOnInit() {
    this.elements = leftMenu;
  }

  /**
   * Menu item click
   *
   * @param item
   */
  menuItemClick(item: any) {
    if (item['sub-menu']) {
      item['sub-menu'].expanded = !item['sub-menu'].expanded;
    } else {
      this.activedPage = item.activePage;
    }
  }
}
