import { createFeatureSelector, createSelector } from '@ngrx/store';
import { uiFeatureKey, UIState } from '../reducers';


// lookup the 'ui' feature state managed by NgRx
const getUItate = createFeatureSelector<UIState>(uiFeatureKey);


// select the notifications
export const selectUINotification = createSelector(getUItate, state => state.notification);

// select version
export const selectUIVersion = createSelector(getUItate, state => state.version);

// select client
export const selectUIClient = createSelector(getUItate, state => state.client);

// select error
export const selectUIError = createSelector(getUItate, state => state.error);

// select loading
export const selectUILoading = createSelector(getUItate, state => state.loading);
