import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { JoinPipe } from './pipes/join.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { IncludePipe } from './pipes/include.pipe';
import { SafePipe } from './pipes/safe.pipe';


@NgModule({
  declarations: [
    JoinPipe,
    ReplacePipe,
    IncludePipe,
    SafePipe
  ],
  exports: [
    JoinPipe,
    ReplacePipe,
    IncludePipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
