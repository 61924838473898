<div class="left-menu-container" [ngClass]="{'menucollapse': !smallMenu}">
  <div class="left-menu-wrapper">

    <div class="sidenav_logo">
      <div class="sidenav-logo-wrapper-small"></div>
      <div class="sidenav-logo-wrapper-extended"></div>
    </div>

    <div class="sidenav_items">
     <app-main-menu></app-main-menu>      
    </div>

    <div class="sidenav_action">
      <div class="mango-icon-wrapper" (click)="openVersionModal()">
        <div class="mango-icon"></div>
      </div>

      <div class="version">V.{{version}}</div>

      <button class="btn btn-default expand-arrow" (click)="switchLeftMenu()">
        <i class="fa" aria-hidden="true" [ngClass]="{'fa-angle-right': smallMenu, 'fa-angle-left': !smallMenu}"></i>
      </button>
    </div>
  </div>
</div>


