import * as fromStore from '@ngrx/store';
import * as fromUI from '../store/reducers/ui.reducer';
import * as fromAuth from '../store/reducers/auth.reducer';
import * as fromRouter from '@ngrx/router-store';

import { environment } from '../../environments/environment';
import { Params, RouterStateSnapshot } from '@angular/router';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface State {
  ui: fromUI.UIState;
  auth: fromAuth.AuthState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: fromStore.ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  ui:   fromUI.uiReducer,
  auth: fromAuth.authReducer
};

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    return { url, params, queryParams };
  }
}

export const metaReducers: fromStore.MetaReducer<State>[] = !environment.production ? [] : [];

