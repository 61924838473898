<ul class="sidenav_menu">
  <li class="sidenav_icon"
      *ngFor="let item of elements"
      [ngClass]="{'active': (activedPage == item.activePage), 'disabled': (item.active == 'no')}"
      (click)="menuItemClick(item)" [routerLink]="'/' + item.link">

    <div class="menu-content">
      <i class="fa" [ngClass]="item.icon" aria-hidden="true"></i>
      <span class="label name">{{item.name}}</span>
    </div>

    <ul class="sub-menu" *ngIf="((item['sub-menu']) && !!(item['sub-menu'].expanded))">
      <li *ngFor="let sub of item['sub-menu']">{{sub.name}}</li>
    </ul>

  </li>
</ul>
