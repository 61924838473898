import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';

// NgRx
import { select, Store } from '@ngrx/store';
import { State } from '../reducers';
import { Observable } from 'rxjs';
import * as fromSelectors from '../store/selectors';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  credentials = new FormGroup({
    user: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  version$: Observable<string>;
  loading$: Observable<boolean>;
  sineUpUrl: string;

  /**
   * Constructor
   *
   * @param {AuthService} authService
   * @param {Store} store
   */
  constructor(
    private authService: AuthService,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.version$ = this.store.pipe(select(fromSelectors.selectUIVersion));
    this.loading$ = this.store.pipe(select(fromSelectors.selectUILoading));
  }

  /**
   * Login
   */
  login(): void {
    this.authService.login(this.credentials.value);
  }
}
