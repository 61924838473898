import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VideoChatService } from '../services/video-chat.service';
import { DatetimeService } from '../services/datetime.service';
import { Store } from '@ngrx/store';
import { setErrorMsj, setSuccessMsj } from '../store/actions';


@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss']
})
export class VideoChatComponent implements OnInit {

  room: string;
  username: string;
  video: string;
  audio: string;
  loading = false;
  linkCopied = false;
  existingRooms = [];
  formRoom: FormGroup;
  isFormView = true;
  publicUrl = 'https://communications.mango-soft.com/public/';

  /**
   * Constructor
   *
   * @param {VideoChatService} vcService
   * @param {ActivatedRoute} actRoute
   * @param {Router} route
   * @param {Store} store
   */
  constructor(
    private vcService: VideoChatService,
    private actRoute: ActivatedRoute,
    private route: Router,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.buildFormFields();

    this.vcService.loadRooms()
      .subscribe((value: any) => {
        this.existingRooms = value;
      });
  }

  /**
   * Build form fields
   */
  buildFormFields(): void {
    const currentDate = DatetimeService.convertUTCToLocalTime();
    this.formRoom = new FormGroup({
      id: new FormControl('', Validators.required),
      activeType: new FormControl('status', Validators.required),
      activated: new FormControl('1'),
      username: new FormControl(''),
      dateFrom: new FormControl(currentDate),
      dateTo: new FormControl(currentDate),
    });
  }

  /**
   * Update room name
   */
  updateRoomName() {
    const roomName = this.formRoom.value.id;
    if (roomName.includes(' ')) {
      this.formRoom.patchValue({
        id: roomName.replace(/ /g, '_'),
      });
    }
  }

  /**
   * Get params
   */
  getParams() {
    this.room = this.actRoute.snapshot.params.room;
    this.username = this.actRoute.snapshot.params.username;
    this.audio = this.actRoute.snapshot.queryParams.audio;
    this.video = this.actRoute.snapshot.queryParams.video;
  }

  /**
   * connect to room
   */
  connectToRoom() {
    this.room = this.formRoom.value.id;
    this.username = this.formRoom.value.username;
    this.isFormView = false;
  }

  /**
   * Save room
   */
  saveRoom() {
    this.loading = true;
    const room = {
      ...this.formRoom.value,
      id: this.formRoom.value.id.trim(),
      activated: (this.formRoom.value.activated === '1'),
      dateFrom: DatetimeService.convertGMTToUTC(this.formRoom.value.dateFrom),
      dateTo: DatetimeService.convertGMTToUTC(this.formRoom.value.dateTo),
    };
    delete room.username;
    this.vcService.saveRooms(room)
      .subscribe((response: any) => {
        if (response.success) {
          this.existingRooms = [...this.existingRooms, room];
          this.store.dispatch(setSuccessMsj({msj: 'Room saved!'}));
          this.loading = false;
        }
      }, (error) => {
        this.loading = false;
        this.store.dispatch(setErrorMsj({msj: 'Could not create room', error}));
      });
  }

  /**
   * Update room
   *
   * @param {string} value
   */
  updateRoom(value: string) {
    let room: any = this.existingRooms.filter(item => item.id === value);
    if (!room.length) {
      return;
    }
    room = room[0];
    this.formRoom.patchValue({
      ...room,
      actived: ('activated' in room && room.activated) ? '1' : '0',
      dateFrom: DatetimeService.convertUTCToLocalTime(room.dateFrom),
      dateTo: DatetimeService.convertUTCToLocalTime(room.dateTo)
    });
  }

}
