import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

// NgRx
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as fromStore from '../store';

export interface IUserCredentials {
  user: string;
  password: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly isAuth$: Observable<boolean> = this.isAuth.asObservable();

  /**
   * Constructor
   *
   * @param {Store} store
   * @param {Router} router
   * @param {HttpClient} http
   * @param {CookieService} cookieService
   */
  constructor(
    private store: Store<State>,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
  }

  /**
   * Get Token
   *
   * @return {string}
   */
  get token(): string {
    return this.cookieService.get('auth_key');
  }

  /**
   * Set Token
   *
   * {string} token
   */
  set token(token: string) {
    this.isAuth.next(true);
    this.cookieService.set('auth_key', token);
    this.cookieService.set('authTimeOut', new Date().getTime().toString());
  }

  /**
   * Remove Token
   */
  removeToken() {
    this.isAuth.next(false);
    this.cookieService.delete('auth_key');
    this.cookieService.delete('authTimeOut');
  }

  /**
   * Log in
   *
   * @param {IUserCredentials} credentials
   * @param {string} currentUrl
   */
  login(credentials: IUserCredentials, currentUrl = '/'): void {
    const payload = {
      username: credentials.user,
      password: credentials.password,
      module: environment.moduleName
    };

    this.http
      .post(`${environment.apiRegistration}/authenticate`, payload)
      .subscribe((response: any) => {
        if (response && 'access_token' in response) {
          this.setData(response);
          this.router.navigate([currentUrl]);
        }
      }, (error) => {
        let err: string;
        try {
          err = error.error;
        } catch (e) {
          err = 'Invalid credentials';
        }
        this.store.dispatch(fromStore.clearUserDataAction());
        this.store.dispatch(fromStore.setErrorMsj({msj: err, error}));
      });
  }

  /**
   * Logout
   */
  logout(): void {
    this.removeToken();
    this.store.dispatch(fromStore.clearUserDataAction());
    this.router.navigate(['/login']);
  }

  /**
   * Refresh token
   */
  getCurrentUser(): void {
    if (!this.token) {
      this.removeToken();
      return;
    }
    const endpoint = `${environment.apiRegistration}/users/current`;
    this.http.get(endpoint)
      .subscribe((response: any) => {
        if (response && 'access_token' in response) {
          this.setData(response);
        } else {
          this.logout();
        }
      }, () => this.logout());
  }

  /**
   * Refresh token
   */
  refreshToken(): void {
    if (!this.token) {
      this.removeToken();
      return;
    }

    if (!this.checkTimeForRefresh()) {
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({'Authorization': this.token})
    };

    const endpoint = `${environment.apiRegistration}/refresh-token`;
    this.http
      .get(endpoint, httpOptions)
      .subscribe((newToken: any) => {
        if ('access_token' in newToken) {
          this.token = newToken.access_token;
        }
      });
  }

  /**
   * Check time for refresh
   */
  checkTimeForRefresh() {
    const authTimeOut = parseInt(this.cookieService.get('authTimeOut'));
    const now = new Date().getTime();
    const min = Math.floor((now - authTimeOut) / 60000);
    return !(min < 60);
  }

  /**
   * Set data
   *
   * @param {*} data
   */
  setData(data: any): void {
    this.token = data.access_token;
    this.store.dispatch(fromStore.setUserDataAction(data.userData));
  }

}
