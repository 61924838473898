import { createAction, props } from '@ngrx/store';


export const uiLoading = createAction('[UI] Loading');
export const uiLoaded = createAction('[UI] Loaded');

export const setVersion = createAction('[UI] Set Version', props<{ version: string }>());
export const setClient = createAction('[UI] Set Client', props<{ client: string }>());

export const setSuccessMsj = createAction('[UI] Set Success Message', props<{ msj: string }>());
export const setErrorMsj = createAction('[UI] Set Error Message', props<{ msj: string, error: any | Error }>());
export const clearMessage  = createAction('[UI] Clear Message');
