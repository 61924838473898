import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor() { }

  /**
   * Convert GMT to UTC
   *
   * @param {string} gmtDateTime
   * @return string
   */
  static convertGMTToUTC(gmtDateTime?: string): string {
    const newDate = (!gmtDateTime) ? new Date() : new Date(gmtDateTime);
    return new Date(newDate.toUTCString()).toISOString();
  }

  /**
   * Convert UTC to local time
   *
   * @param {string} utcDateTime
   * @return Date
   */
  static convertUTCToLocalTime(utcDateTime?: string): Date {
    const newDate = (!utcDateTime) ? new Date() : new Date(utcDateTime);
    return new Date(newDate.toString());
  }
}
