<div class="login-wrapper">
  <div class="login_form_container">
    <div class="login_container">
      <div class="logo zflogohorizontal">
        <!--<img src="../assets/img/logo/logo_mango.png" alt="Logo">-->
      </div>

      <div class="login_form_wrapper">
        <form [formGroup]="credentials">
          <input class="form-control" required id="user_name" type="email" maxLength="100" placeholder="Username*"
                 formControlName="user"/>
          <input class="form-control" required id="password" type="password" placeholder="Password*"
                 formControlName="password"
                 (keyup.enter)="login()"/>
          <div class="submit_container">
            <button class="btn btn-primary zfcolorbg zfcolorborder btn-block"
                    [disabled]="credentials.invalid"
                    (click)="login()">Login
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="loading$ | async" class="login-loading-icon">
        <img src="assets/img/loading.gif" alt="Logo">
        <p class="authenticating">Authenticating plase wait...</p>
      </div>
      <div class="bottom_logo">
        <img src="assets/img/logo/mango-expanded.png" alt="Logo">
        <p class="mt-2">v{{version$ | async}}</p>
      </div>

    </div>
  </div>

  <div class="login_photo" style="background-image:url('assets/img/logo/login_photo.jpg')">

  </div>
</div>

