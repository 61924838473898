import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';

// NgRx
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {

  /**
   * Constructor
   *
   * @param {AuthService} authService
   * @param {Router} router
   */
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403 || (!error.status && !error.error)) {
            this.authService.logout();
          }

          if (error.status === 404) {
            this.router.navigate(['/']);
          }

          return next.handle(request);
        })
      );
  }
}
