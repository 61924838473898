 <div class="container" *ngIf="isFormView">
  <div class="row justify-content-md-center mt-5">
    <div class="col-md-6 col-sm-12">

      <div class="pointer alert alert-success" role="alert"
           (click)="linkCopied = true"
           triggers="mouseenter:mouseleave"
           [popover]="(linkCopied) ? 'copied' : 'click to copy'"
           ngxClipboard [cbContent]="publicUrl + formRoom.get('id').value">
        <i class="fa fa-copy"></i> {{publicUrl}}{{formRoom.get('id').value}}
      </div>

      <div class="card">
        <div class="card-header">
          Rooms
          <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
        </div>
        <form [formGroup]="formRoom">
          <div class="form-group">
            <label for="inpExistingRooms">Existing Rooms</label>
            <select class="form-control" id="inpExistingRooms" (change)="updateRoom($event.target.value)">
              <option selected>Select a room</option>
              <option *ngFor="let r of existingRooms" [value]="r.id">{{r.id | titlecase}}</option>
            </select>
          </div>

          <hr>

          <div class="form-group">
            <label for="inpRoom">Room</label>
            <input type="text" class="form-control" id="inpRoom" placeholder="room1" required
                   (keyup)="updateRoomName()"
                   formControlName="id">
          </div>

          <div class="form-group">
            <label for="inpActiveType">Way to activate the room</label>
            <select class="form-control" id="inpActiveType" required
                   formControlName="activeType">
              <option value="status" selected>Status</option>
              <option value="time">Time</option>
            </select>
          </div>

          <div class="form-group" *ngIf="formRoom.get('activeType').value === 'status'">
            <label for="inpStatus">Status</label>
            <select class="form-control" id="inpStatus"
                    formControlName="activated">
              <option value="1" selected>Enable</option>
              <option value="0">Disable</option>
            </select>
          </div>

          <ng-container *ngIf="formRoom.get('activeType').value === 'time'">
            <div class="form-group">
              <label for="inpdateFrom">Schedule (Format 24hr)</label>
              <input type="text" id="inpdateFrom" class="form-control font-sm"
                     formControlName="dateFrom"
                     #datepickerFull="bsDatepicker"
                     [bsConfig]="{dateInputFormat: 'YYYY/MM/DD, HH:mm:ss'}"
                     bsDatepicker>
            </div>

            <div class="form-group">
              <input type="text" id="inpdateTo" class="form-control font-sm"
                     formControlName="dateTo"
                     #datepickerFull="bsDatepicker"
                     [bsConfig]="{dateInputFormat: 'YYYY/MM/DD, HH:mm:ss'}"
                     bsDatepicker>
            </div>
          </ng-container>

          <hr>

          <div class="form-group">
            <label for="inpUser">Username</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-user"></i></div>
              </div>
              <input type="text" class="form-control" id="inpUser" placeholder="username"
                     formControlName="username">
            </div>
          </div>

          <div class="mt-4">
            <button type="button" class="btn btn-primary"
                    (click)="saveRoom()"
                    [disabled]="formRoom.invalid">Save</button>
            <button type="button" class="ml-2 btn btn-success"
                    (click)="connectToRoom()"
                    [disabled]="formRoom.invalid">Connect</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<div class="video-chat-wrapper" *ngIf="isFormView === false">
  <mangosoft-video-chat
    mainColor="#51789c"
    [autoConnect]="!!(room && username)"
    [roomName]="room"
    [audioDisabled]="(audio === 'false')"
    [videoDisabled]="(video === 'false')"
    [username]="username">
  </mangosoft-video-chat>
</div>
