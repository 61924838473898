<div class="modal-header">
    <h4 class="modal-title pull-left">Release Notes</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <h5>Mango Module Name</h5>
    <table class="table">
      <tr>
        <td width="25%"><b>Version</b></td>
        <td width="75%">1.1.18</td>
      </tr>
      <tr>
        <td><b>Build</b></td>
        <td>123456</td>
      </tr>
      <tr>
        <td class="nowrap"><b>Release date</b></td>
        <td>21/01/18</td>
      </tr>
      <tr>
        <td><b>Notes</b></td>
        <td>Blah, Blah, Blah</td>
      </tr>      
    </table>
  </div>