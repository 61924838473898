import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { topMenu } from '../../../environments/settings';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AuthService } from '../../services/auth.service';

// NgRx
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  @Input() client: string;

  elements: any[];
  userData: any;
  moduleName: string;
  urlEditProfile: string;

  /**
   * Constrcutor
   *
   * @param {Store} store
   * @param {AuthService} authService
   */
  constructor(
    private store: Store<State>,
    private authService: AuthService
  ) {
    this.moduleName = environment.moduleName;
  }

  ngOnInit() {
    this.store.select('auth')
      .pipe(take(1), map(value => value.userData))
      .subscribe(data => this.userData = data);
    this.elements = topMenu;
    this.urlEditProfile = `${environment.registrationURL}/userInfo`;
  }

  /**
   * Logout
   */
  logout(): void {
    this.authService.logout();
  }
}
