import { Action, createReducer, on } from '@ngrx/store';
import * as fromUI from '../actions/ui.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';


export const uiFeatureKey = 'ui';

export interface UIState extends EntityState<any> {
  client: string;
  loading: boolean;
  version: string;
  notification: {
    message: string;
    type: string;
  };
  error: any | Error;
}

export const uiAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const UIInitialState: UIState = uiAdapter.getInitialState({
  client: '',
  loading: false,
  version: '',
  notification: {
    message: '',
    type: null
  },
  error: null
});

const uiReducers = createReducer(
  UIInitialState,
  on(fromUI.uiLoading, (state) => ({...state, loading: true})),
  on(fromUI.uiLoaded, (state) => ({...state, loading: false})),
  on(fromUI.setErrorMsj, (state, { msj, error }) => ({...state,
    notification: {message: msj, type: 'error'}, error
  })),
  on(fromUI.setSuccessMsj, (state, { msj }) => ({...state,
    notification: {message: msj, type: 'success'}
  })),
  on(fromUI.clearMessage, (state) => ({...state,
    notification: {message: '', type: null}, error: null
  })),
  on(fromUI.setVersion, (state, { version }) => ({...state, version})),
  on(fromUI.setClient, (state, { client }) => ({...state, client}))
);

export function uiReducer(state: UIState | undefined, action: Action) {
  return uiReducers(state, action);
}
