import { Component, OnInit } from '@angular/core';

// NgRx
import { State } from '../reducers';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectUIVersion } from '../store/selectors';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  userAccess: string[];
  version: string;
  client: string;

  /**
   * Constructor
   *
   * @param {Store} store
   */
  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.store.pipe(select(selectUIVersion), take(1))
      .subscribe(version => this.version = version);
  }

}
