import { Component, OnInit, Input } from '@angular/core'
import { VersionComponent } from './version/version.component'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  @Input() smallMenu = true;
  @Input() version;

  modalRef: BsModalRef;

  /**
   * Constructor
   *
   * @param {BsModalService} modalService
   */
  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  /**
   * Open version modal
   */
  openVersionModal(): void {
    this.modalRef = this.modalService.show(VersionComponent, {class: 'modal-md'});
  }

  /**
   * Switch left menu
   */
  switchLeftMenu(): void {
    this.smallMenu = !this.smallMenu;
  }
}
