<div class="mango-wrapper">
  <div class="top-bar">
    <app-top-menu [client]="client"></app-top-menu>
  </div>

  <div class="left_menu">
    <app-left-menu [version]="version"></app-left-menu>
  </div>

  <div class="main-content" >
    <router-outlet></router-outlet>
    <simple-notifications></simple-notifications>
  </div>
</div>
