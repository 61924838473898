// Components
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { MainMenuComponent } from './main-menu/main-menu.component';


export const components = [
  LeftMenuComponent,
  TopMenuComponent,
  MainMenuComponent
];

