<div class="top-menu-manager zfheadercolorbg">

  <div class="app-title"><span>{{(client === 'ccf') ? 'TV - ConcacafGo' : moduleName | replace: '_': ' '}}</span></div>

  <div class="profile">

    <!-- Language --
    <ul class="language disabled">
      <li (click)="change_language('en')" [ngClass]="{'inactive': language == 'es' }">EN</li>
      <li (click)="change_language('es')" [ngClass]="{'inactive': language == 'en' }">ES</li>
    </ul>
    -->

    <div class="btn-group user-profile" dropdown>
      <button placement="bottom" container="body" containerClass="profilePopover" type="button" class="btn btn-transparent"
              [popover]="popProfile"
              [outsideClick]="true">
        <img class="avatar" src="{{userData?.picture}}" alt="">
      </button>

      <ng-template #popProfile>
        <ul class="profile-container" role="menu">
          <li class="username"><span>{{userData?.name}}</span></li>
          <li *ngIf="urlEditProfile">
            <a class="dropdown-item" href="{{urlEditProfile}}"><i class="fa fa-user"></i> Edit Profile</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
