import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

// NgRx
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';


@Injectable()

export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuth$
      .pipe(tap(value => {
        if (value === false) {
          this.router.navigate(['/login']);
        }
        return value;
      }));
  }
}
