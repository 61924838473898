
<div class="container">
  <div class="row justify-content-md-center mt-5">
    <div class="col-md-6 col-sm-12">

      <div *ngIf="validate; then validateTemplate else formTemplate"></div>

      <ng-template #validateTemplate>
        <div class="card">
          <div class="card-header">
            Enter your code
            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
          </div>
          <form [formGroup]="authForm" (ngSubmit)="validateCode()">
            <div class="form-group">
              <input id="single-factor-code-text-field" autocomplete="one-time-code" type="text" class="form-control"
                     formControlName="code"/>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-success">validate</button>
              <span class="ml-3 pointer text-muted" (click)="sendValidationCode()">Resend code</span>
            </div>
          </form>
        </div>

        <div class="pointer alert alert-warning mt-4" role="alert" *ngIf="notification">
          {{notification}}
        </div>
      </ng-template>

      <ng-template #formTemplate>
        <div *ngIf="shortLink" class="pointer alert alert-success" role="alert"
             (click)="linkCopied = true"
             ngxClipboard [cbContent]="shortLink"
             triggers="mouseenter:mouseleave"
             [popover]="(linkCopied) ? 'copied' : 'click to copy'">
          <i class="fa fa-copy"></i>
          {{shortLink}}
        </div>

        <div class="card">
          <div class="card-header">
            Created Room
            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
          </div>
          <form [formGroup]="authForm" (ngSubmit)="submit()">
            <div class="form-group">
              <label for="inpRoom">Room</label>
              <input #roomInp type="text" class="form-control" id="inpRoom" placeholder="room1" required
                     formControlName="room">
              <!--
              <div class="valid-feedback">Valid.</div>
              <div class="invalid-feedback">Please fill out this field.</div>
              -->
            </div>
            <div class="form-group">
              <label for="inpUser">Username</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-user"></i></div>
                </div>
                <input type="text" class="form-control" id="inpUser" placeholder="username" required
                       formControlName="username">
              </div>
            </div>
            <div class="form-group">
              <label for="inpEmail">Email</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">@</div>
                </div>
                <input type="email" class="form-control" id="inpEmail" placeholder="email@domain.com" required
                       formControlName="email">
              </div>
            </div>
            <div class="form-group">
              <label for="inpPhone">Phone</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fa fa-phone"></i></div>
                </div>
                <input type="text" class="form-control" id="inpPhone" placeholder="+17860000000" required
                       formControlName="phone">
              </div>
            </div>
            <div class="form-group">
              <label for="inpExpDate">Exp Date</label>
              <input type="text" id="inpExpDate" class="form-control" required
                     formControlName="date"
                     #datepickerFull="bsDatepicker"
                     [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD, H:mm:ss' }"
                     bsDatepicker>
            </div>
            <button type="submit" class="btn btn-success"
                    [disabled]="authForm.invalid">Generate link</button>
          </form>
        </div>
      </ng-template>

    </div>
  </div>
</div>
