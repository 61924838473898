// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared.module';

// components
import { components } from './index';
import { VersionComponent } from './left-menu/version/version.component';


@NgModule({
  declarations: [
    ...components,
    VersionComponent,
    ],
  exports: [
    ...components,
    VersionComponent,
    ],
  entryComponents: [
    VersionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,

    // NgbModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    // TooltipModule.forRoot(),
    // TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  providers: []
})

export class ComponentsModule { }
