import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'include'
})
export class IncludePipe implements PipeTransform {

  transform(listStr: string[], includeStr: string): boolean {
    return listStr.includes(includeStr);
  }

}
